<template>
  <v-container>
    <BaseTitlePage :title="checkPageTitle">
      <template slot="button" v-if="checkSub">
        <div class="b-pages__content__sub">
          <h5 class="b-pages__content__sub--title">Estipulante</h5>
          <span>
            {{ $route.params.socialName }}
          </span>
          <span>
            {{ formatCnpj($route.params.cnpj) }}
          </span>
        </div>
      </template>
    </BaseTitlePage>

    <v-tabs
      show-arrows
      class="b-tab-vertical"
      :vertical="checkMobile"
      v-model="tab"
    >
      <v-tab
        id="tab-registration-data"
        class="b-tab-vertical--tab"
        href="#tab-1"
      >
        <v-icon small v-if="checkMobile">fa-regular fa-file-lines</v-icon>
        Dados cadastrais
      </v-tab>
      <v-tab id="tab-address" class="b-tab-vertical--tab" href="#tab-2">
        <v-icon small v-if="checkMobile">fa-regular fa-map</v-icon>
        Endereço
      </v-tab>
      <v-tab id="tab-contatcts" class="b-tab-vertical--tab" href="#tab-3">
        <v-icon small v-if="checkMobile">fa-regular fa-address-card</v-icon>
        Contatos
      </v-tab>

      <v-tabs-items v-model="tab" class="b-tab-vertical--items">
        <v-tab-item value="tab-1">
          <v-form
            @submit.prevent="nextTab2"
            ref="formTab1"
            lazy-validation
            v-model="validFormTab1"
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <div class="b-title-tabs">Informe os dados cadastrais</div>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-text-field
                    id="document"
                    name="document"
                    label="CNPJ"
                    dense
                    outlined
                    required
                    :rules="[required, cnpj]"
                    v-mask="'##.###.###/####-##'"
                    v-model="item.document"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-text-field
                    id="socialName"
                    name="socialName"
                    label="Razão Social"
                    maxlength="120"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.socialName"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-text-field
                    id="fantasyName"
                    name="fantasyName"
                    label="Nome Fantasia"
                    maxlength="70"
                    dense
                    outlined
                    v-model="item.fantasyName"
                  />
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <BaseCnaesAutocomplete
                    id="cnaeId"
                    name="cnaeId"
                    label="CNAE"
                    required
                    :rules="[required]"
                    :cnae="item.cnae"
                    v-model="item.cnaeId"
                  />
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <BaseLegalOrigin
                    id="legalOriginId"
                    name="legalOriginId"
                    label="Natureza jurídica"
                    required
                    :rules="[required]"
                    v-model="item.legalOriginId"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <BaseBusinessUnit
                    id="businessUnitId"
                    name="businessUnitId"
                    label="Unidades de negócio"
                    :disabled="editMode"
                    :rules="[required]"
                    v-model="item.businessUnitId"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xl="5"
                  lg="5"
                  md="5"
                  sm="5"
                  v-if="item.businessUnitId"
                >
                  <BaseUserByBusinessUnit
                    id="userId"
                    name="userId"
                    label="Usuários"
                    :disabled="editMode"
                    :rules="[required]"
                    :businessUnit="item.businessUnitId"
                    v-model="item.userId"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <BaseDatePicker
                    id="startDate"
                    name="startDate"
                    label="Data de início"
                    required
                    :rules="[required]"
                    v-model="item.startDate"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" v-if="editMode">
                  <v-autocomplete
                    id="situation"
                    name="situation"
                    label="Status"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    :items="situation"
                    v-model="item.situation"
                  />
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-5" />

              <v-row dense>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-checkbox
                    id="exemptStateRegistration"
                    name="exemptStateRegistration"
                    label="Inscrição estadual é isento?"
                    class="ma-0"
                    color="primary"
                    v-model="item.exemptStateRegistration"
                  />
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-checkbox
                    id="exemptMunicipalRegistration"
                    name="exemptMunicipalRegistration"
                    label="Inscrição municipal é isento?"
                    class="ma-0"
                    color="primary"
                    v-model="item.exemptMunicipalRegistration"
                  />
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    id="stateRegistration"
                    name="stateRegistration"
                    label="Informe a inscrição estadual"
                    maxlength="9"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    :disabled="item.exemptStateRegistration"
                    v-model="item.stateRegistration"
                    v-if="!item.exemptStateRegistration"
                  />
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    id="municipalRegistration"
                    name="municipalRegistration"
                    label="Informe a inscrição municipal"
                    maxlength="11"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    :disabled="item.exemptMunicipalRegistration"
                    v-model="item.municipalRegistration"
                    v-if="!item.exemptMunicipalRegistration"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-5" />

              <v-row dense>
                <v-col class="text-right">
                  <BaseButton
                    id="btn-back-tab-1"
                    name="btn-back-tab-1"
                    title="Voltar"
                    color="primary"
                    outlined
                    @click="back"
                  />
                  <BaseButton
                    id="btn-save-tab-1"
                    name="btn-save-tab-1"
                    title="Avançar"
                    color="primary"
                    type="submit"
                    :disabled="!validFormTab1"
                    v-permission="{
                      roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                      operator: '!=='
                    }"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-form
            @submit.prevent="nextTab3"
            ref="formTab2"
            lazy-validation
            v-model="validFormTab2"
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <div class="b-title-tabs">Informe o endereço</div>
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="4">
                  <BaseZipcode
                    id="zipCode"
                    name="zipCode"
                    label="CEP"
                    required
                    :rules="[required, zipcode]"
                    v-model="item.zipCode"
                    @fill="fill($event)"
                  />
                </v-col>
                <v-col cols="12" xl="9" lg="9" md="9" sm="8">
                  <v-text-field
                    id="address"
                    name="address"
                    label="Endereço"
                    maxlength="120"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.address"
                  />
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                  <v-text-field
                    id="number"
                    name="number"
                    label="Número"
                    maxlength="7"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.number"
                  />
                </v-col>
                <v-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <v-text-field
                    id="complement"
                    name="complement"
                    label="Complemento"
                    dense
                    outlined
                    v-model="item.complement"
                  />
                </v-col>
                <v-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <v-text-field
                    id="district"
                    name="district"
                    label="Bairro"
                    maxlength="100"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.district"
                  />
                </v-col>
                <v-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <v-text-field
                    id="city"
                    name="city"
                    label="Cidade"
                    maxlength="100"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.city"
                  />
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                  <BaseStates
                    id="state"
                    name="state"
                    label="UF"
                    required
                    :rules="[required]"
                    v-model="item.state"
                  />
                </v-col>
                <v-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <v-text-field
                    id="phoneNumber"
                    name="phoneNumber"
                    label="DDD e Telefone"
                    maxlength="15"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.phoneNumber"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  />
                </v-col>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <v-text-field
                    id="webSite"
                    name="webSite"
                    label="WebSite"
                    maxlength="100"
                    dense
                    outlined
                    v-model="item.webSite"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-5" />

              <v-row dense>
                <v-col class="text-right">
                  <BaseButton
                    id="btn-back-tab-2"
                    name="btn-back-tab-2"
                    title="Voltar"
                    color="primary"
                    outlined
                    @click="back"
                  />
                  <BaseButton
                    id="btn-save-tab-2"
                    name="btn-save-tab-2"
                    title="Avançar"
                    color="primary"
                    type="submit"
                    :disabled="!validFormTab2"
                    v-permission="{
                      roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                      operator: '!=='
                    }"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-container fluid>
            <v-form
              ref="formTab3"
              lazy-validation
              v-model="validFormTab3"
              v-permission="{
                roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                operator: '!=='
              }"
            >
              <v-row dense>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between align-center mb-2"
                >
                  <div class="b-title-tabs mb-0">Informe o responsável</div>
                  <template
                    v-if="
                      checkSub &&
                      !item.contacts.find(x => x.type === 'Principal')
                    "
                  >
                    <v-btn
                      text
                      class="pa-1"
                      color="primary"
                      @click="searchMainContact"
                      v-permission="{
                        roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                        operator: '!=='
                      }"
                    >
                      <v-icon left>fa-regular fa-id-badge</v-icon>
                      Vincular contato do estipulante
                    </v-btn>
                  </template>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    label="Nome"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="contact.name"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <v-text-field
                    id="document"
                    name="document"
                    label="CPF"
                    dense
                    outlined
                    required
                    :rules="[required, cpf]"
                    v-mask="'###.###.###-##'"
                    v-model="contact.document"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <v-text-field
                    id="area"
                    name="area"
                    label="Departamento"
                    maxlength="100"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="contact.area"
                  />
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    id="email"
                    name="email"
                    label="Email"
                    maxlength="200"
                    dense
                    outlined
                    required
                    :rules="[required, email]"
                    v-model="contact.email"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <v-text-field
                    label="Telefone"
                    maxlength="15"
                    dense
                    outlined
                    required
                    v-model="contact.phoneNumber"
                    v-mask="'(##) ####-####'"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <v-text-field
                    id="phoneExtension"
                    name="phoneExtension"
                    label="Ramal"
                    maxlength="10"
                    dense
                    outlined
                    v-model="contact.phoneExtension"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <v-text-field
                    id="mobileNumber"
                    name="mobileNumber"
                    label="Celular"
                    maxlength="15"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="contact.mobileNumber"
                    v-mask="'(##) #####-####'"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                  <v-autocomplete
                    id="type"
                    name="type"
                    label="Tipo"
                    dense
                    outlined
                    required
                    :items="types"
                    :rules="[required]"
                    v-model="contact.type"
                  />
                </v-col>
                <v-col
                  v-permission="{
                    roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                    operator: '!=='
                  }"
                >
                  <BaseButton
                    @click="addContact"
                    color="secondary"
                    title="Adicionar"
                    height="40"
                    v-if="!isEditContact"
                  />
                  <BaseButton
                    @click="cancelContact"
                    color="secondary"
                    title="Cancelar"
                    height="40"
                    outlined
                    v-if="isEditContact"
                  />
                  <BaseButton
                    @click="changeContact"
                    color="secondary"
                    title="Atualizar"
                    height="40"
                    v-if="isEditContact"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-row :class="item.contacts.length === 0 && 'pt-5'">
              <v-col
                cols="12"
                xl="4"
                lg="6"
                md="6"
                sm="6"
                v-for="(item, index) in item.contacts"
                :key="index"
              >
                <div class="b-card-contact">
                  <ul>
                    <li class="b-card-contact--columns">
                      <h5>{{ item.name }}</h5>
                      <div class="b-card-contact--actions">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="editContact(item)"
                              v-permission="{
                                roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                                operator: '!=='
                              }"
                            >
                              <v-icon color="primary" small>
                                fa-solid fa-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.type !== 'Principal'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="confirmeDeleteContact(item)"
                              v-permission="{
                                roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                                operator: '!=='
                              }"
                            >
                              <v-icon color="error" small>
                                fa-regular fa-trash-can
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Excluir</span>
                        </v-tooltip>
                      </div>
                    </li>
                    <li>
                      <span>{{ formatCpf(item.document) }}</span>
                    </li>
                    <li>
                      <span>{{ item.area }}</span>
                    </li>
                    <li>
                      <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </li>
                    <li class="b-card-contact--columns">
                      <span>{{ formatPhone(item.mobileNumber) }}</span>
                      <span v-if="item.phoneExtension">
                        {{ `Ramal: ${item.phoneExtension}` }}
                      </span>
                      <div class="b-card-contact--actions">
                        <v-chip
                          small
                          color="primary"
                          :outlined="item.type !== 'Principal'"
                        >
                          {{ item.type }}
                        </v-chip>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-5" />

            <v-row>
              <v-col class="text-right">
                <BaseButton
                  @click="back"
                  outlined
                  color="primary"
                  title="Voltar"
                />
                <BaseButton
                  @click="finishTab"
                  color="primary"
                  title="Salvar"
                  v-permission="{
                    roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3'],
                    operator: '!=='
                  }"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { CustomerService } from '@/services/api/customers';
import { rulesMixin } from '@/mixins/rules';
import { unmask, randomId } from '@/helpers/utils';
import { confirmMessage, showMessage } from '@/helpers/messages';
import {
  formatPhone,
  formatCpf,
  formatCnpj,
  formatDate
} from '@/helpers/formatting';

export default {
  mixins: [rulesMixin],

  data: () => ({
    menu: false,
    validFormTab1: true,
    validFormTab2: true,
    validFormTab3: true,
    isEditContact: false,
    tab: null,
    situation: ['Ativo', 'Inativo'],
    item: {
      address: null,
      businessUnitId: null,
      city: null,
      cnaeId: null,
      complement: null,
      contacts: [],
      customerCode: null,
      district: null,
      document: null,
      exemptMunicipalRegistration: false,
      exemptStateRegistration: false,
      fantasyName: null,
      id: null,
      legalOriginId: null,
      municipalRegistration: null,
      number: null,
      parentId: null,
      phoneNumber: null,
      situation: null,
      socialName: null,
      startDate: null,
      stateRegistration: null,
      state: null,
      webSite: null,
      zipCode: null,
      userId: null
    },
    contact: {
      id: null,
      name: null,
      area: null,
      document: null,
      mobileNumber: null,
      phoneNumber: null,
      phoneExtension: null,
      email: null,
      type: null
    }
  }),

  created() {
    if (this.editMode) {
      this.search();
    }
  },

  computed: {
    editMode() {
      return this.$route.params && this.$route.params.id ? true : false;
    },

    checkSub() {
      const { cnpj, socialName } = this.$route.params;

      if (cnpj && socialName) {
        return true;
      } else {
        return false;
      }
    },

    checkPageTitle() {
      const { sub, id } = this.$route.params;

      if (sub) {
        return id ? 'Visualizar subestipulante' : 'Cadastrar subestipulante';
      } else {
        return id ? 'Visualizar estipulante' : 'Cadastrar estipulante';
      }
    },

    checkMobile() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },

    types() {
      const hasMain = this.item.contacts.find(x => x.type === 'Principal');

      if (hasMain) {
        return ['Outros'];
      } else {
        return ['Principal', 'Outros'];
      }
    }
  },

  methods: {
    formatPhone,
    formatCpf,
    formatCnpj,
    formatDate,

    fill(params) {
      if (params) {
        this.item.address = params.logradouro;
        this.item.district = params.bairro;
        this.item.city = params.localidade;
        this.item.state = params.uf;
      }
    },

    back() {
      this.$router.push({ name: 'estipulantes' });
    },

    nextTab2() {
      if (!this.$refs.formTab1.validate(true)) {
        return;
      }

      this.tab = 'tab-2';
    },

    nextTab3() {
      if (!this.$refs.formTab2.validate(true)) {
        return;
      }

      this.tab = 'tab-3';
    },

    addContact() {
      if (!this.$refs.formTab3.validate(true)) {
        return;
      }

      if (this.contact.type === 'Principal') {
        const exist = this.item.contacts.find(
          x => x.type === this.contact.type
        );

        if (exist) {
          showMessage(
            'error',
            'Não é possivel adicionar mais de um contato como principal!'
          );
          return;
        }
      }

      this.contact.id = randomId();
      this.contact.phoneNumber = unmask(this.contact.phoneNumber);
      this.contact.mobileNumber = unmask(this.contact.mobileNumber);
      this.contact.document = unmask(this.contact.document);

      this.item.contacts.push(Object.assign({}, this.contact));
      this.cancelContact();
    },

    changeContact() {
      if (!this.$refs.formTab3.validate(true)) {
        return;
      }

      if (this.contact.type === 'Principal') {
        const exist = this.item.contacts.find(
          x => x.type === this.contact.type && x.id !== this.contact.id
        );

        if (exist) {
          showMessage(
            'error',
            'Não é possivel adicionar mais de um contato como principal'
          );
          return;
        }
      }

      this.contact.phoneNumber = unmask(this.contact.phoneNumber);
      this.contact.mobileNumber = unmask(this.contact.mobileNumber);
      this.contact.document = unmask(this.contact.document);

      let index = this.item.contacts.findIndex(x => x.id === this.contact.id);

      if (index !== -1) {
        this.item.contacts[index] = Object.assign({}, this.contact);
      }

      this.isEditContact = false;
      this.$refs.formTab3.reset();
    },

    editContact(item) {
      this.isEditContact = true;

      this.contact = Object.assign({}, item);
    },

    cancelContact() {
      this.isEditContact = false;
      this.$refs.formTab3.reset();
    },

    async confirmeDeleteContact(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        const index = this.item.contacts.findIndex(
          x => unmask(x.document) === unmask(item.document)
        );

        if (index !== -1) {
          this.item.contacts.splice(index, 1);
        }
      }
    },

    finishTab() {
      const params = Object.assign({}, this.item);
      params.document = unmask(params.document);
      params.zipCode = unmask(params.zipCode);
      params.phoneNumber = unmask(params.phoneNumber);
      params.parentId = params.parentId
        ? params.parentId
        : this.$route.params.parentId;

      if (params.contacts.length === 0) {
        showMessage('error', 'Obrigatório adicionar pelo menos 1 contato');
        return;
      }

      if (this.editMode) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const customerService = new CustomerService();
        const { status } = await customerService.postCustomer(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.back();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const customerService = new CustomerService();
        const { status } = await customerService.putCustomer(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.back();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const customerService = new CustomerService();
        const { status, data } = await customerService.getCustomerById(
          this.$route.params
        );

        if (status === 200) {
          this.item = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async searchMainContact() {
      try {
        const { parentId } = this.$route.params;
        const customerService = new CustomerService();
        const { status, data } = await customerService.getMainContact(parentId);

        if (status === 200) {
          this.contact = data;
          this.contact.linkStipulatorMainContact = true;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>

<style lang="scss">
.b-title-tabs {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
</style>
